@mixin rSize($font-size) {
  @media (min-width: 1920px) {
    font-size: $font-size *1.4;
  }
  @media (max-width: 1919px) {
    font-size: $font-size *1.3;
  }
  @media (max-width: 1439px) {
    font-size: $font-size *1.2;
  }
  @media (max-width: 1023px) {
    font-size: $font-size *1.1;
  }
  @media (max-width: 599px) {
    font-size: $font-size *1;
  }
}
@mixin rSizeTitleCard($font-size) {
  @media (min-width: 1920px) {
    font-size: calc( #{$font-size} * 1.4 );
  }
  @media (max-width: 1919px) {
    font-size: calc( #{$font-size} * 1.3 );
  }
  @media (max-width: 1439px) {
    font-size: calc( #{$font-size} * 1.2 );
  }
  @media (max-width: 1023px) {
    font-size: calc( #{$font-size} * 1.1 );
  }
  @media (max-width: 599px) {
    font-size: calc( #{$font-size} * 1 );
  }
}
@mixin rSizeContainer($width ,$height: none ) {
  @media (min-width: 1920px) {
    width: calc( #{$width} *1.5 );
    @if $height != none {
      height: calc( #{$height} *1.5);
    }
  }
  @media (max-width: 1919px) {
    width: calc( #{$width} *1.4 );
    @if $height != none {
      height: calc( #{$height} *1.4);
    }
  }
  @media (max-width: 1439px) {
    width: calc( #{$width} *1.25 );
    @if $height != none {
      height: calc( #{$height} *1.25);
    }
  }
  @media (max-width: 1023px) {
    width: calc( #{$width} *1.1 );
    @if $height != none {
      height: calc( #{$height} *1.1);
    }
  }
  @media (max-width: 599px) {
    width: calc( #{$width} *1 );
    @if $height != none {
      height: calc( #{$height} *1);
    }
  }
}
@mixin rSizeExpandContainer($width) {
  @media (min-width: 1920px) {
    width: $width*1.8;
    height: ($width*1.8)*.9;
  }
  @media (max-width: 1919px) {
    width: $width*1.6;
    height: ($width*1.6)*.9;
  }
  @media (max-width: 1439px) {
    width: $width*1.4;
    height: ($width*1.4)*.9;
  }
  @media (max-width: 1023px) {
    width: $width*1.2;
    height: ($width*1.2)*.9;
  }
  @media (max-width: 599px) {
    width: $width*1;
    height: ($width*1)*.9;
  }
}
@mixin rSizeHeightContainer($height) {
  @media (min-height: 1920px) {
    height: $height*1.8;
  }
  @media (max-height: 1919px) {
    height: $height*1.6;
  }
  @media (max-height: 1439px) {
    height: $height*1.4;
  }
  @media (max-height: 1023px) {
    height: $height*1.2;
  }
  @media (max-height: 599px) {
    height: $height*1;
  }
}
@mixin rSizeHeightNotifications($height) {
  @media (min-width: 1920px) {
    height: $height*1.8;
  }
  @media (max-width: 1919px) {
    height: $height*1.6;
  }
  @media (max-width: 1439px) {
    height: $height*1.4;
  }
  @media (max-width: 1023px) {
    height: $height*1.2;
  }
  @media (max-width: 599px) {
    height: $height*1;
  }
}
@mixin rSizeContainerSelect($width) {
  @media (min-width: 1920px) {
    width: $width * 1.4;
    height: ($width * 1.4) * 1.1;
  }
  @media (max-width: 1919px) {
    width: $width * 1.3;
    height: ($width * 1.3) * 1.1;
  }
  @media (max-width: 1439px) {
    width: $width * 1.2;
    height: ($width * 1.2) * 1.1;
  }
  @media (max-width: 1023px) {
    width: $width * 1.1;
    height: ($width * 1.1) * 1.1;
  }
  @media (max-width: 599px) {
    width: $width;
    height: ($width) * 1.1;
  }
}
@mixin rSizeExpandTextContainer($width) {
  @media (min-width: 1920px) {
    width: $width*1.8;
  }
  @media (max-width: 1919px) {
    width: $width*1.6;
  }
  @media (max-width: 1439px) {
    width: $width*1.4;
  }
  @media (max-width: 1023px) {
    width: $width*1.2;
  }
  @media (max-width: 599px) {
    width: $width*1;
  }
}
@mixin rSizeIcon($font-size) {
  @media (min-width: 1920px) {
    font-size: $font-size *1.4;
  }
  @media (max-width: 1919px) {
    font-size: $font-size *1.3;
  }
  @media (max-width: 1439px) {
    font-size: $font-size *1.2;
  }
  @media (max-width: 1023px) {
    font-size: $font-size *1.1;
  }
  @media (max-width: 599px) {
    font-size: $font-size *1;
  }
}
@mixin rSizeIcon2($font-size) {
  @media (min-width: 1920px) {
    font-size: $font-size *3;
  }
  @media (max-width: 1919px) {
    font-size: $font-size *2.5;
  }
  @media (max-width: 1439px) {
    font-size: $font-size *1.5;
  }
  @media (max-width: 1023px) {
    font-size: $font-size *1.3;
  }
  @media (max-width: 599px) {
    font-size: $font-size *2;
  }
}
@mixin rAbsPosition($left ,$bottom ) {
  @media (min-width: 1920px) {
    left: $left*1.25;
    bottom: $bottom*1.25;
  }
  @media (max-width: 1919px) {
    left: $left*1.2;
    bottom: $bottom*1.2;
  }
  @media (max-width: 1439px) {
    left: $left*1.175;
    bottom: $bottom*1.175;
  }
  @media (max-width: 1023px) {
    left: $left*1.05;
    bottom: $bottom*1.05;
  }
  @media (max-width: 599px) {
    left: $left*1;
    bottom: $bottom*1;
  }
}
@mixin rSize2($size) {
  @media (min-width: 1920px) {
    size: $size *1.4;
  }
  @media (max-width: 1919px) {
    size: $size *1.3;
  }
  @media (max-width: 1439px) {
    size: $size *1.2;
  }
  @media (max-width: 1023px) {
    size: $size *1.1;
  }
  @media (max-width: 599px) {
    size: $size *1;
  }
}