@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass'

@import 'src/css/responsiveSize'
.imgContainer
  display: flex
  justify-content: center
  align-items: center

.btnCont
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  @include rAbsPosition($left:2.5rem ,$bottom: 3.1rem)
  // left: 3.125rem
  // bottom: 3.875rem
  z-index:1
    // 3.75 - 2.5
    // 4.65 - 3.1

.abs
  position: absolute

.img
  display: flex
  justify-content: center
  align-items: center
  // left: -5rem
  // bottom: 8rem
  padding: 0
  z-index:0

.avatar
  display: flex
  justify-content: center
  align-items: center
  @include rSize(8rem)
  // @include rSizeContainer($width : 5rem,$height: 5rem,)
