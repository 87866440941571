@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
// app global css in Sass form

@tailwind base;
@tailwind components;
@tailwind utilities;

// Font Palette
$darkD: #4D4D4D;
$gray8: #808080;
$lightgray: #B2B2B2;
$gray97: #979797;
$lightergray: #CCCCCC;
$gray-e5: #E5E5E5;
$gray-f2: #F2F2F2;
$gray-e6: #E6E6E6;
$dark-33: #333333;
$green-dark: #156A5F;
$blue-dark: #2C6086;
$blue-t: #428BC1;
$white: #fff;
$lightblue: #F6F9FC;

.text-darkD {
  color: $darkD;
}
.bg-darkD {
  background: $darkD;
}
.text-lightblue {
  color: $lightblue;
}
.bg-lightblue {
  background: $lightblue;
}
.text-gray97 {
  color: $gray97;
}
.bg-gray97 {
  background: $gray97;
}
.text-gray8 {
  color: $gray8;
}
.bg-gray8 {
  background: $gray8;
}
.text-lightergray {
  color: $lightergray;
}
.bg-lightergray {
  background: $lightergray;
}
.text-lightgray {
  color: $lightgray;
}
.bg-lightgray {
  background: $lightgray;
}
.text-graye5 {
  color: $gray-e5;
}
.bg-graye5 {
  background: $gray-e5;
}
.text-gray-f2 {
  color: $gray-f2;
}
.bg-gray-f2 {
  background: $gray-f2;
}
.text-graye6 {
  color: $gray-e6;
}
.bg-graye6 {
  background: $gray-e6;
}
.text-dark-33 {
  color: $dark-33;
}
.bg-dark-33 {
  background: $dark-33;
}
.bg-green-dark {
  background: $green-dark;
}
.bg-blue-dark {
  background: $blue-dark;
}
.bg-blue-t {
  background: $blue-t;
}
.text-green-dark {
  color: $green-dark;
}
.text-blue-dark {
  color: $blue-dark;
}
.text-blue-t {
  color: $blue-t;
}// Background Colors
$landing-bg-1: #F7F7F7;

.bg-landing-bg-1 {
  background: $landing-bg-1;
}

// Separator Color

$separatorColor: #A3D5C780;

.text-separatorColor {
  color: $separatorColor;
}
.bg-separatorColor {
  background: $separatorColor;
}

$avatar-outline-colors: ("primary":$primary, "accent":$accent, "white": $white);
@each $colorit, $value in $avatar-outline-colors {
  .avatar-outline-#{$colorit} {
    width: 80% !important;
    height: 80% !important;
    box-shadow: 0 0 0 2pt $value;
  }
  .avatar-outline-semibold-#{$colorit} {
    width: 80% !important;
    height: 80% !important;
    box-shadow: 0 0 0 3.5pt $value;
  }
  .avatar-outline-bold-#{$colorit} {
    width: 80% !important;
    height: 80% !important;
    box-shadow: 0 0 0 5pt $value;
  }
}

// card Outline Border Color
$card-outline-colors: ("gray": $gray-e5, "primary":$primary, "blue-t":$blue-t, "secondary":$secondary);
@each $colorit, $value in $card-outline-colors {
  .card-outline-#{$colorit} {
    border: 1px solid $value;
  }
  .card-outline-bold-#{$colorit} {
    border: 2px solid $value;
  }
}

.avatar-40 {
  font-size: 50px;
}
.avatar-25 {
  height: 2rem;
  width: 2rem;
  font-size: 2rem;
}

@import 'responsiveSize';

.bigTitle {
  @include rSize(1.5rem);
}
.title {
  @include rSize(1.4rem);
}
.subtitle {
  @include rSize(1rem);
}
.body {
  @include rSize(1rem);
}
.body-sm {
  @include rSize(.8rem);
}
.text-title-xl {
  @include rSize(3rem);
}
.text-title-lg {
  @include rSize(2rem);
}
.text-bigTitle {
  @include rSize(1.5rem);
}
.text-title {
  @include rSize(1.4rem);
}
.text-subtitle {
  @include rSize(1rem);
}
.text-body-md {
  @include rSize(1.2rem);
}
.text-body {
  @include rSize(1rem);
}
.text-body-sm {
  @include rSize(.8rem);
}
.text-body-xs {
  @include rSize(.6rem);
}

.avatar-toolbar {
  @include rSize(30px);
  @include rSizeContainer($width : 30px,$height: 30px, );
}
.avatar-solicitud {
  @include rSize(30px);
  @include rSizeContainer($width : 25px,$height: 25px, );
}
.avatar-paciente {
  @include rSize(30px);
  @include rSizeContainer($width : 20px,$height: 20px, );
}
.avatar-sidebar {  // @include rSize(30px)
  @include rSizeContainer($width : 5.5rem,$height: 5.5rem, );
}
.avatar-perfil-lg {  // @include rSize(30px)
  @include rSizeContainer($width : 6rem,$height: 6rem, );
}

//Sessions card Designs

@import 'src/css/cardSessions.scss';

// Add asterisc to all blitzform labels in the initial evaluation
// .q-field__native[required] ~ .blitz-field__label:after
.requiredBlitzar .blitz-field__label:not(.noRequired):after {
  content: '*';
  color: red;
}
.reactive-field {
  margin-top: -15px;
  padding-left: 16px;
}
.circle-avatar-img {
  object-fit: cover;
  border-radius: 50%;
}

.page-break-after-always {
  page-break-after: always;
}