
@import 'src/css/responsiveSize'

// .center
//   display: flex
//   justify-content: center
//   align-items: center
  // padding: 10px

.idCont
  display: flex
  justify-content: center
  align-items: center
  flex-direction: row
  padding: 10px

.button
  // min-width: 15rem !important
  @include rSize(.7rem)
  @include rSizeContainer(13rem)
  padding:.5rem

.button-unselected
  // min-width: 15rem !important
  padding:.5rem
  @include rSize(.7rem)
  @include rSizeContainer(13rem)
  background-color: black

.avatar
  display: flex
  justify-content: center
  align-items: center
  @include rSize(3rem)

.cancel
  text-decoration: underline
  cursor: pointer
.q-field--error .control
  margin-left: 36px
