//Session Skeleton Design
.sesionSK {
  background: linear-gradient(180deg, rgb(234, 236, 236) 0%, #bdc2c2cc 19%, #9b9e9e99 42%, #8c8f8f73 66%, #3e414000 100%);
  background-color: white;
  border: 2px solid #525252;
  z-index: 0;
}
.circle1-SK {
  width: 80%;
  height: 50%;
  background: #929292;
  // border-radius: 50%
  position: absolute;
  bottom: -20%;
  left: 20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle2-SK {
  width: 40%;
  height: 40%;
  background: #b6d6c5;
  // border-radius: 50%
  position: absolute;
  bottom: 10%;
  left: -15%;
  border-radius: 50% !important;
  filter: blur(55px);
  z-index: -1;
}
.circle3-SK {
  width: 80%;
  height: 40%;
  background: #a1a1a1;
  // border-radius: 50%
  position: absolute;
  top: -10%;
  right: -20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle4-SK {
  width: 25%;
  height: 20%;
  background: white;
  // border-radius: 50%
  position: absolute;
  top: 30%;
  right: -10%;
  border-radius: 50% !important;
  filter: blur(50px);
  z-index: -1;
}
// Consejo Breve sesion
.sesion0 {
  background: linear-gradient(180deg, rgb(66, 119, 193) 0%, rgba(66, 139, 193,0.8) 19%, rgba(66, 139, 193,0.6) 42%, rgba(66, 139, 193,0.45) 66%, rgba(66, 139, 193,0) 100%);
  background-color: white;
  border: 2px solid #428BC1;
  z-index: 0;
}
.circle1-0 {
  width: 80%;
  height: 50%;
  background: #C4C4C4;
  // border-radius: 50%
  position: absolute;
  bottom: -20%;
  left: 20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle2-0 {
  width: 40%;
  height: 40%;
  background: #FA9E9F;
  // border-radius: 50%
  position: absolute;
  bottom: 10%;
  left: -15%;
  border-radius: 50% !important;
  filter: blur(55px);
  z-index: -1;
}
.circle3-0 {
  width: 80%;
  height: 40%;
  background: #F7F7F7;
  // border-radius: 50%
  position: absolute;
  top: -10%;
  right: -20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle4-0 {
  width: 25%;
  height: 20%;
  background: white;
  // border-radius: 50%
  position: absolute;
  top: 30%;
  right: -10%;
  border-radius: 50% !important;
  filter: blur(50px);
  z-index: -1;
}
// Style used in the cards of the sessions page
.sesion1 {
  background: linear-gradient(180deg, rgb(250, 158, 159) 0%, rgba(250,158,159,0.8) 19%, rgba(250,158,159,0.6) 42%, rgba(250,158,159,0.45) 66%, rgba(250,158,159,0) 100%);
  background-color: white;
  border: 2px solid #fa9e9f;
  z-index: 0;
}
.circle1-1 {
  width: 80%;
  height: 50%;
  background: #78977E;
  // border-radius: 50%
  position: absolute;
  bottom: -20%;
  left: 20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle2-1 {
  width: 40%;
  height: 40%;
  background: #FFBC88;
  // border-radius: 50%
  position: absolute;
  bottom: 10%;
  left: -15%;
  border-radius: 50% !important;
  filter: blur(55px);
  z-index: -1;
}
.circle3-1 {
  width: 80%;
  height: 40%;
  background: #FFE1CA;
  // border-radius: 50%
  position: absolute;
  top: -10%;
  right: -20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle4-1 {
  width: 25%;
  height: 20%;
  background: white;
  // border-radius: 50%
  position: absolute;
  top: 30%;
  right: -10%;
  border-radius: 50% !important;
  filter: blur(50px);
  z-index: -1;
}
//Session1 Design
.sesion2 {
  background: linear-gradient(180deg, rgb(30, 148, 133) 0%, rgba(30, 148, 133,0.8) 19%, rgba(30, 148, 133,0.6) 42%, rgba(30, 148, 133,0.45) 66%, rgba(30, 148, 133,0) 100%);
  background-color: white;
  border: 2px solid #1E9485;
  z-index: 0;
}
.circle1-2 {
  width: 80%;
  height: 50%;
  background: #78977E;
  // border-radius: 50%
  position: absolute;
  bottom: -20%;
  left: 20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle2-2 {
  width: 40%;
  height: 40%;
  background: #FFBC88;
  // border-radius: 50%
  position: absolute;
  bottom: 10%;
  left: -15%;
  border-radius: 50% !important;
  filter: blur(55px);
  z-index: -1;
}
.circle3-2 {
  width: 80%;
  height: 40%;
  background: #FFE1CA;
  // border-radius: 50%
  position: absolute;
  top: -10%;
  right: -20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle4-2 {
  width: 25%;
  height: 20%;
  background: white;
  // border-radius: 50%
  position: absolute;
  top: 30%;
  right: -10%;
  border-radius: 50% !important;
  filter: blur(50px);
  z-index: -1;
}
.sesion3 {
  background: linear-gradient(180deg, rgb(66, 139, 193) 0%, rgba(66, 139, 193,0.8) 19%, rgba(66, 139, 193,0.6) 42%, rgba(66, 139, 193,0.45) 66%, rgba(66, 139, 193,0) 100%);
  background-color: white;
  border: 2px solid #428BC1;
  z-index: 0;
}
.circle1-3 {
  width: 80%;
  height: 50%;
  background: #C4C4C4;
  // border-radius: 50%
  position: absolute;
  bottom: -20%;
  left: 20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle2-3 {
  width: 40%;
  height: 40%;
  background: #FA9E9F;
  // border-radius: 50%
  position: absolute;
  bottom: 10%;
  left: -15%;
  border-radius: 50% !important;
  filter: blur(55px);
  z-index: -1;
}
.circle3-3 {
  width: 80%;
  height: 40%;
  background: #F7F7F7;
  // border-radius: 50%
  position: absolute;
  top: -10%;
  right: -20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle4-3 {
  width: 25%;
  height: 20%;
  background: white;
  // border-radius: 50%
  position: absolute;
  top: 30%;
  right: -10%;
  border-radius: 50% !important;
  filter: blur(50px);
  z-index: -1;
}
.sesion4 {
  background: linear-gradient(180deg, rgb(255, 188, 136) 0%, rgba(255, 188, 136,0.8) 19%, rgba(255, 188, 136,0.6) 42%, rgba(255, 188, 136,0.45) 66%, rgba(255, 188, 136,0) 100%);
  background-color: white;
  border: 2px solid #FFBC88;
  z-index: 0;
}
.circle1-4 {
  width: 80%;
  height: 50%;
  background: #FA9E9F;
  // border-radius: 50%
  position: absolute;
  bottom: -20%;
  left: 20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle2-4 {
  width: 40%;
  height: 40%;
  background: #FA9E9F;
  // border-radius: 50%
  position: absolute;
  bottom: 10%;
  left: -15%;
  border-radius: 50% !important;
  filter: blur(55px);
  z-index: -1;
}
.circle3-4 {
  width: 80%;
  height: 40%;
  background: #F7F7F7;
  // border-radius: 50%
  position: absolute;
  top: -10%;
  right: -20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle4-4 {
  width: 25%;
  height: 20%;
  background: white;
  // border-radius: 50%
  position: absolute;
  top: 30%;
  right: -10%;
  border-radius: 50% !important;
  filter: blur(50px);
  z-index: -1;
}
.sesion5 {
  background: linear-gradient(180deg, rgb(177, 88, 133) 0%, rgba(177, 88, 133,0.8) 19%, rgba(177, 88, 133,0.6) 42%, rgba(177, 88, 133,0.45) 66%, rgba(177, 88, 133,0) 100%);
  background-color: white;
  border: 2px solid #B15885;
  z-index: 0;
}
.circle1-5 {
  width: 80%;
  height: 50%;
  background: #FFBC88;
  // border-radius: 50%
  position: absolute;
  bottom: -20%;
  left: 20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle2-5 {
  width: 40%;
  height: 40%;
  background: #FA9E9F;
  // border-radius: 50%
  position: absolute;
  bottom: 10%;
  left: -15%;
  border-radius: 50% !important;
  filter: blur(55px);
  z-index: -1;
}
.circle3-5 {
  width: 80%;
  height: 40%;
  background: #FFBC88;
  // border-radius: 50%
  position: absolute;
  top: -10%;
  right: -20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle4-5 {
  width: 25%;
  height: 20%;
  background: white;
  // border-radius: 50%
  position: absolute;
  top: 30%;
  right: -10%;
  border-radius: 50% !important;
  filter: blur(50px);
  z-index: -1;
}
.sesion6 {
  background: linear-gradient(180deg, rgb(23, 181, 171) 0%, rgba(23, 181, 171,0.8) 19%, rgba(23, 181, 171,0.6) 42%, rgba(23, 181, 171,0.45) 66%, rgba(23, 181, 171,0) 100%);
  background-color: white;
  border: 2px solid #17B5AB;
  z-index: 0;
}
.circle1-6 {
  width: 80%;
  height: 50%;
  background: #5E9BE9;
  // border-radius: 50%
  position: absolute;
  bottom: -20%;
  left: 20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle2-6 {
  width: 40%;
  height: 40%;
  background: #FFFFFF;
  // border-radius: 50%
  position: absolute;
  bottom: 10%;
  left: -15%;
  border-radius: 50% !important;
  filter: blur(55px);
  z-index: -1;
}
.circle3-6 {
  width: 80%;
  height: 40%;
  background: #40C0E7;
  // border-radius: 50%
  position: absolute;
  top: -10%;
  right: -20%;
  border-radius: 50% !important;
  filter: blur(60px);
  z-index: -1;
}
.circle4-6 {
  width: 25%;
  height: 20%;
  background: white;
  // border-radius: 50%
  position: absolute;
  top: 30%;
  right: -10%;
  border-radius: 50% !important;
  filter: blur(50px);
  z-index: -1;
}